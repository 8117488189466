import { Component, Vue } from 'vue-property-decorator'


//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import FieldSearch from '@/components/fieldSearch/FieldSearch.vue'

//wrappers
import {
    WForm,
    Box,
    WButton,
    WSubtitle
} from '@/components/wrappers';

@Component({
    components: {
        PrivateContent,
        FieldSearch,
        WForm,
        Box,
        WButton,
        WSubtitle
    }
})

export default class Page404 extends Vue {

	mounted(){
		document.title = "404! Página não Encontrada"
	}

}